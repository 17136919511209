import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { Button } from './Button';


function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick =() => setClick(!click);
  const closeMobileMenu= () => setClick(false);

  // show button pending on Screen size
  const showButton = () => {
    if(window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true)
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
        <nav className='navbar'>
            <div className='navbar-container'>
                <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                    Massillargues 
                  </Link>
                <div className='menu-icon' onClick={handleClick}>
                  <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className='nav-item'>
                    <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                      Accueil
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/Domaine' className='nav-links' onClick={closeMobileMenu}>
                      Le Domaine
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/Gite' className='nav-links' onClick={closeMobileMenu}>
                      Le Gite
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/dispoTarifs' className='nav-links' onClick={closeMobileMenu}>
                      Tarifs et dispo
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/Contacts' className='nav-links' onClick={closeMobileMenu}>
                      Contacts
                    </Link>
                  </li>

                </ul>

            </div>
        </nav>
    </>
  );
}

export default Navbar;
