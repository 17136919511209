import React from 'react'
import '../../App.css'
import './Domaine.css'



function Domaine() {
    return (
        <>
        <div className='content'>
            <h1>Domaine de Massillargues</h1>
 
            <div class="wrapper">
                <article class="img_cont">
                    <img class="img" src="/images_mslg/DSC_0606.webp" alt="four a pain et cour de ferme"/>  
                </article>
                <article class="text_cont">
                    <p class="text">
                        Nous sommes installés ici depuis 1994 avec pour objectif de faire revivre ce domaine en lui gardant son activité agricole authentique.
                        L'exploitation est toujours en activité et s'étend sur 230 hectares:<br></br>
                        110 hectares de prairies sont consacrés à un élevage bovin<br></br>
                        120 hectares de forêt<br></br>
                        Ces activités ne créent pas de nuisances pour le gite et ne troublent pas le calme du domaine.
                    </p>  
                </article>   
            </div> 
            <div class="wrapper">
               <article class="text_cont">
                    <p class="text">
                    Les batiments sont nombreux et ont été construits à différentes époques  du XIII ème siecle à nos jours. <br></br>
                    Ils ont beaucoup de caractère avec les pierres de schiste apparentes et les tuiles romaines. <br></br>
                    Leur entretien est un budget important que nous assurons en partie grace à la location du Gite.
                    </p>  
                </article>   
                <article class="img_cont">
                    <img class="img" src="/images_mslg/DSC_0603.webp" alt="vieille bergerie cour de ferme et hortensia"/>  
                </article>
            </div>     
        </div>
        
        
        </>
    )

}

export default Domaine;










/*
            <div class="wrapper">
                <article class="main">
                    <p>
                        Nous sommes installés ici depuis 1994 avec pour objectif de faire revivre ce domaine en lui gardant son activité agricole authentique.
                        L'exploitation est toujours en activité et s'étend sur 230 hectares:
                        L'exploitation est toujours en activité et s'étend sur 230 hectares: 
    - 110 hectares de prairies sont consacrés à :
l'élevage de moutons (550 brebis en plein-air)
l'élevage de Poneys  Connemara et Shetland
et depuis peu un petit rucher
Ces activités ne créent pas de nuisances pour les gites et ne troublent pas le calme du domaine.
   - 120 hectares de forêt 1/3 résineux 2/3 feuillus. De belles promenades sont possibles dans cette forêt privée. 
                    </p> 
                    </article>
                    <body></body>
                        
                      
                </div>  */