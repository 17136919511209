import React from 'react'
import '../../App.css'
import './Contact.css'




function Contact() {

    return(
        <>
            <div className='contentcontact'>
                <div className='aroundcontact'>Contacts</div>
                <div className='middle_div'>
                    <div className='img_content'>
                        <img class="img" src="/images_mslg/contacts_pic.webp" alt="veaux soleil couchant"/>
                    </div>
                    <div className='wrappercontactcol'>
                            <div className='row'>
                            <i className='fa fa-user' />
                                <p className="text">
                                Loïc LE GARSMEUR
                                </p>
                            </div>
                            <div className='row'>
                                <i className='fa fa-map-pin' />
                                <p className="text">
                                Sarl Pastorale de la Montagne Noire<br></br>
                                Domaine de Massillargues<br></br>
                                11310 SAISSAC
                                </p>
                            </div>
                            <div className='row'>
                                <i className='fa fa-phone' />
                                <p className="text">
                                06 70 43 13 20
                                </p>
                            </div>
                            <div className='row'>
                            <i className='fa fa-envelope' />
                            <p className="text">
                            gite.saissac@gmail.com
                            </p>
                            </div>
                    </div>
                </div>    
                <div className='middle_div2'>
                <iframe
                    title='SaissacMap'  
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19509.092865439245!2d2.1257026742193665!3d43.376103910114026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ae474381b29f39%3A0xdebd3fdae2688eef!2sDomaine%20de%20MASSILLARGUES!5e0!3m2!1sen!2sfr!4v1681664833482!5m2!1sen!2sfr"
                    width="100%"
                    height="450px"
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                    style={{border:0}}
            />
                 
                </div>  
                {/* <div className='Around'>3</div>     */}
                </div>
        </>
    )

}

export default Contact
