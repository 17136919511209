import React from 'react';
import '../../App.css'
import { Button } from '../Button';
import './Tarifdispo.css'
function Tarifdispo() {
   return(
    <>
      
      <div className='contenttaro'>
          <div className='aroundtaro'>Tarifs et disponibilités</div>
          <div className='middle_divtaro'>
            <div className='img_content_taro'>
                <img class="img taro" src="/images_mslg/7.webp" alt="vue des pyrénées"/>
            </div>
            <div className='wrappercontactcol'>
                    <div className='row text'>
                    Location à la semaine en période de vacances scolaires.
                    </div>
                    <div className='row text'>
                  Pour toutes réservations, merci de nous contacter par email ou téléphone.                  
                    </div>
                    <div className='row'>
                    <Button
                      className='btns'
                      buttonStyle='btn--gdc'
                      buttonSize='btn--large'
                      linkto='/Contacts'
                      // target="_blank"
                      rel="noopener noreferrer"
                    >
                    Contacts <i className='far fa-play-circle' />
                    </Button>
                  </div>
                  
                  <div className='row text'>
                  Pour les week-ends, hors saison, à partir de 300 € le week-end, nous contacter.
                  </div>
              </div>
              <div className='center'>
                <iframe 
                  src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ3_b-HEtN3AYnnh_xU9jSy1aQL8kXq-XN7FMuRljSsy0BLm9tya-VgIxqjnHCCkyoxXkp68R15C6ZP/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false">
                </iframe> 
              </div>
              <div className='buttonbottom'>
              <Button
                      className='btns'
                      buttonStyle='btn--gdc'
                      buttonSize='btn--large'
                      linkto='https://docs.google.com/spreadsheets/d/e/2PACX-1vQ3_b-HEtN3AYnnh_xU9jSy1aQL8kXq-XN7FMuRljSsy0BLm9tya-VgIxqjnHCCkyoxXkp68R15C6ZP/pubhtml?gid=0&single=true'
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                    Ouvrir dans un autre onglet <i className='far fa-play-circle' />
                  </Button>
              </div>
          </div>
        </div>
    </>
   ) 
}

export default Tarifdispo;