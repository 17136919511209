import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <>
            <div classname="footer">
                <p>Copyright ©-All rights are reserved</p>
            </div> 
        </>
    );


}

export default Footer;