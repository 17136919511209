import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
    return (
      <div className='hero-container'>
        {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
        <h1>Nature</h1>
        <h1>Calme</h1>
        <h1>Grands espaces</h1>
        <h1></h1>
        <p>Domaine de Massillargues</p>
        <div className='hero-btns'>
          <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            linkto='/Domaine'
          >
            Le Domaine
          </Button>
          <Button
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--large'
            onClick={console.log('hey')}
            linkto='/Gite'
          >
            Le Gîte 
            {/* <i className='far fa-play-circle' /> */}
          </Button>
        </div>
      </div>
    );
  }
  

export default HeroSection
