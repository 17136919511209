import React from 'react'
import '../../App.css'
import './Gite.css'



function Gite() {
    return (
        <>
                    
        <div className='content gite'>
            <h1>La Maison du Vacher</h1>
 
            <div id="first" class="wrapper">
                <article class="img_cont">
                    <img class="img" src="/images_mslg/Courgite2halfg1.webp" alt="vue exterieure gite et cour"/>  
                </article>
                <article class="text_cont">
                    <p class="text">
                    C'est l'ancienne "maison du vacher". Elle est indépendante et mitoyenne de l'ancienne étable en pierre, qui n'est plus utilisée depuis 50 ans.
Sans vis à vis, elle donne sur l'ancienne cour de ferme où les enfants peuvent jouer tranquillement; la route départementale est à plus de 1 kilomètre...<br></br>

Ses plus:<br></br>
-Elle est très bien isolée d'un point de vue thermique et phonique.<br></br>
-La grande salle du rez de chaussé est claire et spacieuse.<br></br>
-Les nombreux points d'eau adaptés aux familles nombreuses.
                    </p>  
                </article>   
            </div> 
            <div class="wrapper">
                <div class="wrapper3 cont3">
                        <img class="img" src="/images_mslg/1.webp" alt="salon"/>  
                        <p class="text">
                        Vaste pièce de séjour 60 m2 avec cheminée, canapé lit , prise TV.<br></br>
                        Terrasse, petit jardin, salon de jardin,    barbecue
                        </p>
                </div>
                <div class="wrapper3 cont3">  
                        <img class="img" src="/images_mslg/13.webp" alt="cuisine"/>  
                        <p class="text">
                        et partie cuisine , équipée de lave linge, lave-vaisselle et micro-onde...<br></br>
                        Placards, W-C séparés.
                        </p>
                </div>
            </div>
            <div class="wrapper">
                 <div class="wrapper3 cont3">  
                        <img class="img" src="/images_mslg/11.webp" alt="chambre double"/>  
                        <p class="text">
                        1 chambre  avec  2 lits en  90 cm et une grande couette, avec lavabos et placards,<br></br>
                        </p>
                </div>
                <div class="wrapper3 cont3">
                        <img class="img" src="/images_mslg/8.webp" alt="chambre simple"/>  
                        <p class="text">
                        1 chambre  avec  1 lit 90 cm,
                        </p>
                </div>
                <div class="wrapper3 cont3">  
                        <img class="img" src="/images_mslg/10.webp" alt="chambre double"/>  
                        <p class="text">
                        1 chambre  avec 2 lits  90 cm couettes individuelles, avec lavabos et étagéres,
                        </p>
                </div>
            </div>
            <div class="wrapper">
                <div class="wrapper3 cont3">
                        <img class="img" src="/images_mslg/12.webp" alt="salle de bain et salle de douche"/>  
                        <p class="text">
                        1 salle de bain, 1 salle d'eau et un WC séparé
                        </p>
                </div> 
                <div class="wrapper3 cont3">
                        <img class="img" src="/images_mslg/9.webp" alt="chambre double lit séparés"/>  
                        <p class="text">
                        1 chambre  avec 2 lits  90 cm couettes individuelles, avec lavabos et placards,
                        </p>
                </div>   
            </div>
        </div>
        
        </>
    )

}

export default Gite;