import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import Domaine from './components/pages/Domaine'
import Gite from './components/pages/Gite'
import Tarifdispo from './components/pages/Tarifdispo'
import Contact from './components/pages/Contact'

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/Domaine' element={<Domaine />}></Route>
          <Route path='/Gite' element={<Gite />}></Route>
          <Route path='/DispoTarifs' element={<Tarifdispo />}></Route>
          <Route path='/Contacts' element={<Contact />}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
